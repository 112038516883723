import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';

const ConfigContext = createContext();

export const useConfig = () => useContext(ConfigContext);
export const ConfigProvider = ({ children }) => {
  const [configData, setConfigData] = useState({});
  const [productTypeData, setProductTypeData] = useState([]);
  const [treatmentTypeData, setTreatmentTypeData] = useState([]);
  const [isLoading, setIsLoading] = useState(true)


  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch config data
        const configResponse = await axios.get(`${process.env.REACT_APP_API_URL}config`);
        setConfigData(configResponse.data.data[0]);

        // Fetch product category data
        const productType = await axios.get(`${process.env.REACT_APP_API_URL}productCategory`);
        setProductTypeData(productType.data.data);

         // Fetch treatments data
         const treatmentsType = await axios.get(`${process.env.REACT_APP_API_URL}treatments`);
         setTreatmentTypeData(treatmentsType.data.data);
         setIsLoading(false)
      } catch (error) {
        setIsLoading(false)
        console.error('Error fetching data:', error);
      }
    };
    if (isLoading) {
      fetchData();
    }
  }, []);
  return (
    <ConfigContext.Provider value={{ configData, productTypeData,treatmentTypeData }}>
      {children}
    </ConfigContext.Provider>
  );
};
